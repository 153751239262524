.subscription-confirm {
  display: grid;
  grid-template-rows: 200px auto 200px;
}
.subscription-confirm-content {
  grid-row-start: 2;
}
.subscription-confirm-content_greeting {
  display: grid;
  gap: 24px;
  align-content: center;
  width: 100%;
  height: 100%;
}
