.delivery-form--text-left {
  text-align: left;
}
.delivery-form--text-right {
  text-align: right;
}
.delivery-form__billing-title {
  margin-bottom: 14px;
}
.delivery-form__expand-shipping_btn {
  font-weight: 600;
  line-height: 16px;
}
.delivery-form__expand-shipping_btn:global(.ant-btn-sm) {
  font-size: 12px;
}
.delivery-form__shipping-address {
  opacity: 0;
  animation: fade-in 0.8s;
  animation-fill-mode: forwards;
}
.delivery-form__shipping-address--visibility {
  width: 100%;
  height: auto;
  margin: 0 0 16px;
}
.delivery-form__consent-checkbox_confirm :global(.ant-checkbox-disabled .ant-checkbox-inner) {
  background-color: var(--main-orange-8);
  border-color: var(--main-orange-8);
}
.delivery-form__consent-checkbox_confirm :global(.ant-checkbox-disabled + span) {
  min-height: 26px;
  margin-bottom: 6px;
  color: inherit;
}
.delivery-form__checkbox {
  min-height: 26px;
}
.delivery-form__checkbox:global(.ant-form-item .ant-checkbox) {
  color: var(--main-gray-9);
}
.delivery-form__consent_privacy-policy-link {
  align-items: flex-start;
}

@keyframes fade-in {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 376px;
    opacity: 1;
  }
}
