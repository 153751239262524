.list-item {
  display: block;
  padding: 0;
}
.list-item:global(.ant-list-item-meta-content) {
  width: auto;
  color: var(--main-gray-9);
  text-align: left;
}
.list-item:global(.ant-list-item-meta-title) {
  font-size: 16px;
  line-height: 16px;
  color: var(--main-gray-9);
}
.list-item:global(.ant-list-item-meta-description) {
  font-size: 14px;
  line-height: 24px;
  color: var(--neutral-6);
}
.list-item-meta {
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.list-item-meta.mobile {
  margin: 16px 0;
}
.list-item-meta.mobile:global(.ant-list-item-meta-content) {
  text-align: center;
}
.list-item-meta.mobile:global(.ant-list-item-meta-description) {
  font-size: 12px;
}
.list-item-header {
  margin-bottom: 24px;
}
.list-item-header-tablet {
  margin-bottom: 16px;
}
.list-item-header-mobile {
  margin-bottom: 16px;
}
.list-item-desc {
  margin-top: 8px;
  margin-bottom: 24px;
}
.list-item-desc-tablet {
  margin-top: 8px;
  margin-bottom: 24px;
}
.list-item-desc-mobile {
  margin-bottom: 16px;
  text-align: center;
}
