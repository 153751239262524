.light-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.light-list-item {
  min-width: 16px;
  min-height: 16px;
}
.light-list-item-loading :global .ant-skeleton-input {
  width: 40px;
  min-width: auto;
  height: 40px;
  line-height: 1;
}
.light-list-vertical {
  display: flex;
  flex-direction: column;
}
.light-list-horizontal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.light-list-vertical .light-list-item {
  padding-top: 8px;
}
.light-list-horizontal .light-list-item {
  margin-left: 10px;
}
.light-list-vertical .light-list-item:first-child {
  padding-top: 0;
}
.light-list-horizontal .light-list-item:first-child {
  margin-left: 0;
}
.light-list-item-icon {
  height: inherit;
}
.light-list-item-icon-actions {
  padding: 12px;
}
