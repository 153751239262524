:global .ant-list-item {
  padding: 12px 0;
}
:global .ant-list-item-meta-title {
  margin-bottom: 2px;
}
:global .ant-list-item-meta-description {
  font-size: 14px;
}
:global .ant-list-item-meta-avatar {
  margin-right: 12px;
}
.list {
  height: inherit;
}
