.steps:global(.ant-steps-dot .ant-steps-item-title) {
  font-size: 14px;
  font-weight: 600;
}
.steps:global(.ant-steps .ant-steps-item-wait .ant-steps-item-title) {
  font-weight: 400;
  color: var(--main-gray-9);
}
.steps:global(.ant-steps.ant-steps-label-vertical .ant-steps-item-content) {
  margin-top: 0;
}
.steps:global(.ant-steps.ant-steps-dot .ant-steps-item-icon) {
  position: relative;
  top: -12px;
  width: 24px;
  height: 24px;
  margin-left: 58px;
  line-height: 24px;
}
.steps:global(.ant-steps.ant-steps-dot .ant-steps-item-tail) {
  top: 0;
}
.steps:global(.ant-steps.ant-steps-dot .ant-steps-item-tail::after) {
  width: calc(100% - 32px);
  height: 1px;
  margin-left: 16px;
}
.steps:global(.ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon) {
  position: relative;
  top: -12px;
  width: 24px;
  height: 24px;
  margin-left: 58px;
  line-height: 24px;
}
.steps:global(.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail) {
  top: 0;
}
.steps:global(.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-title) {
  font-size: 16px;
  font-weight: 600;
}
.steps:global(.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after) {
  width: calc(100% - 32px);
  height: 1px;
  margin-left: 16px;
}
.steps:global(
    .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after
  ) {
  background-color: var(--primary-blue-8);
}
.steps:global(
    .ant-steps
      .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title
  ) {
  color: var(--main-gray-9);
}
