.error-fallback {
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
.error-message {
  margin-bottom: 16px;
}
.illustration {
  width: 100%;
  min-height: 400px;
  margin-bottom: 16px;
}
.description {
  margin: 20px 0;
}
