.faq-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
  &::marker {
    content: "";
  }
  &::-webkit-details-marker {
    display: none;
  }
}
.faq-item__header-icon svg {
  width: 16px;
  height: 16px;
}
.faq-item__body {
  padding-bottom: 16px;
  padding-left: 16px;
}
