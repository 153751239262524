.collapse {
  display: flex;
  flex-wrap: wrap;
}
.collapse-gutter-bottom {
  margin-bottom: 16px;
}
.visible-content {
  width: 100%;
}
.hidden-content {
  width: 100%;
}
.more-button-left {
  width: 100%;
  text-align: left;
}
.icon-position-right {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  align-items: center;
  justify-content: left;
}
.icon-position-right span {
  margin-left: 0 !important;
}
