.rar__section {
  padding-top: 40px;
}
.rar__title {
  margin-bottom: 12px;
}
.rar-instruction__img {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}
.rar-instruction__subtitle {
  margin-bottom: 12px;
}
.rar-instruction__options {
  padding-left: 16px;
  margin: 0;
  & > li::marker {
    font-weight: bold;
  }
}
.rar-instruction__item {
  margin-bottom: 8px;
}
.rar-faq__options {
  padding: 16px 32px;
  margin-bottom: 0;
  list-style: disc;
  background-color: var(--orange-2);
}
.rar-faq__option {
  padding: 16px 32px;
  margin-bottom: 0;
  list-style: disc;
  background-color: var(--orange-2);
}
.rar-card {
  height: 100%;
  padding: 12px 24px;
  background: var(--orange-2);
}
.rar-card__item-row {
  height: 100%;
}
.rar-card__item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
