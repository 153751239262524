.privacy-policy {
  p {
    margin: 12px 0;
  }
  .managing-board {
    p {
      margin: 8px 0;
    }
  }
  .contacts {
    p {
      margin: 2px 0;
    }
  }
  h1 {
    margin-top: 0;
  }
  h2 {
    margin: 40px 0 16px;
  }
  h3 {
    margin: 16px 0 12px;
  }
}
.cookies-table {
  margin: 12px 0;
  thead {
    width: 100%;
    height: 40px;
    background: var(--main-gray-2);
    th {
      width: 50%;
      border-right: 1px solid var(--main-gray-3);
      border-bottom: 1px solid var(--main-gray-3);
    }
    th:last-child {
      border-left: 0;
    }
  }
  tbody {
    tr {
      td {
        padding: 4px 12px;
        border-right: 1px solid var(--main-gray-3);
        border-bottom: 1px solid var(--main-gray-3);
      }
      td:last-child {
        border-right: 0;
      }
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }
}
