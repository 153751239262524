.basket-checkout-delivery-address {
  margin-bottom: 24px;
}
.basket-checkout-delivery-address__icon {
  color: var(--neutral-75);
}
.basket-checkout-delivery-address__content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 8px;
  background-color: var(--primary-blue-1);
  border-radius: 4px;
}
.skeleton {
  line-height: 20px;
}
.skeleton:global.ant-skeleton.ant-skeleton-element .ant-skeleton-input {
  width: 100%;
  height: 18px;
  margin-bottom: 2px;
}
.skeleton-title:global.ant-skeleton.ant-skeleton-element .ant-skeleton-input,
.skeleton-title:global.ant-skeleton.ant-skeleton-element {
  width: 100%;
}
