.btn {
  height: auto;
  min-height: 32px;
  transition: height 0s;
}
.btn:global(.ant-btn-link) {
  color: var(--primary-blue-8);
}
.btn:global(.ant-btn) {
  font-family: var(--font-second);
  font-weight: 700;
  line-height: 22px;
  white-space: pre-line;
  border-radius: 2px;
}
.btn:global(.ant-btn-lg) {
  min-height: 40px;
  font-size: 14px;
}
.btn:global(.ant-btn-sm) {
  min-height: 24px;
  font-size: 12px;
}
.btn:global(.anticon + span) {
  vertical-align: initial;
}
.btn:global(.ant-btn-link):hover,
.btn:global(.ant-btn-link):focus {
  color: var(--primary-blue-6);
}
.anticon .btn:global(.anticon + .anticon) {
  line-height: 0;
}
.btn.intro-navs {
  padding: 2px 3px;
  color: var(--white);
  background-color: var(--corporate-orange);
  border-color: var(--corporate-orange);
}
.btn.intro-navs:hover,
.btn.intro-navs:active {
  background-color: var(--main-orange-8);
  border-color: var(--main-orange-8);
}
.btn .delete-photo {
  color: var(--cta-2);
  transition: 0.15s ease-in-out opacity;
}
.btn .delete-photo:hover {
  opacity: 0.8;
}
.btn.cart {
  color: var(--white);
  background-color: var(--cta-2);
  border-color: var(--cta-2);
  transition: 0.2s ease-in-out background-color;
}
.btn.cart:global(.ant-btn-default:disabled) {
  color: var(--main-gray-7);
  background-color: var(--main-gray-2);
  border: none;
}
.btn.cart:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover),
.btn.cart:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover),
.btn.cart:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active),
.btn.cart:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):focus) {
  color: var(--white);
  background-color: var(--cta-12);
  border: 1px solid var(--cta-12);
}
.btn.table-link {
  font-family: var(--font-base);
  font-weight: 400;
  line-height: var(--article-link-line-height);
  color: var(--primary-blue-8);
  user-select: all;
}
.btn.table-link:global(.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover) {
  color: var(--primary-blue-6);
}
.intro-navs:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover),
.intro-navs:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active) {
  background-color: var(--main-orange-8);
  border-color: var(--main-orange-8);
}
.btn.no-style {
  width: auto;
  height: auto;
  min-height: auto;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: none;
}
.btn.extra-large {
  height: 56px;
  font-weight: 600;
  line-height: 24px;
}
.btn.icon:global(.ant-btn) {
  color: var(--main-gray-5);
  background-color: unset;
  border-color: transparent;
}
.btn.icon:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled)):hover,
.btn.icon:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled)):focus {
  color: var(--primary-blue-8);
  background-color: var(--primary-blue-2);
  border-color: var(--primary-blue-2);
}
.btn.icon:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled)):active,
.btn.icon-active:global(.ant-btn) {
  color: var(--primary-blue-8);
  background-color: var(--primary-blue-3);
  border-color: var(--primary-blue-3);
}
.btn.transparent {
  color: var(--white);
  background-color: transparent;
  border-color: var(--blue-7);
  border-radius: 2px;
}
.btn.transparent:global(.ant-btn-primary:not(:disabled):not(.ant-btn-disabled)):hover,
.btn.transparent:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled)):hover,
.btn.transparent:global(.ant-btn-default:not(:disabled):not(.ant-btn-disabled)):focus {
  color: var(--white);
  background-color: transparent;
  border-color: var(--white);
}
