.skeleton {
  width: 100%;
  padding-top: 16px;
  padding-left: 16px;
  text-align: left;
}
.skeleton-head {
  width: 100%;
}
.skeleton-head:not(:last-child) {
  margin-bottom: 14px;
}
.skeleton-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.skeleton-content:not(:last-child) {
  margin-bottom: 14px;
}
.skeleton-content :global(.ant-skeleton-avatar) {
  width: 16px;
  height: 16px;
  margin: 4px 0;
}
.skeleton-content :global(.ant-skeleton-input) {
  height: 16px;
  margin: 4px 0;
}
.skeleton-avatar {
  flex: 0;
  padding-right: 14px;
}
.skeleton-text {
  flex: 0 1 65%;
}
