.layout {
  position: relative;
  min-width: auto;
  height: 100vh;
}
.layout:global(.ant-layout) {
  min-height: 100%;
  font-family: var(--font-family);
  background: transparent;
}
.layout.private {
  min-width: var(--container-width);
}
