.layout-markdown {
  position: relative;
  width: 100%;
  height: auto;
  font-family: var(--font-base);
  font-size: 14px;
  line-height: 20px;
  h1,
  h2,
  h3,
  h4 {
    font-family: var(--font-second);
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h3 {
    font-size: 18px;
    line-height: 26px;
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
  }
  h5 {
    font-size: 14px;
    line-height: 20px;
  }
  p {
    margin-top: 0;
    margin-bottom: 8px;
  }
  ul,
  ol {
    padding-left: 20px;
    margin-top: 0;
    margin-bottom: 0;
  }
  li::marker {
    font-weight: bold;
  }
  h2 + h4 {
    margin-top: 16px;
  }
  details {
    p {
      margin-bottom: 4px;
    }
    h5 {
      margin-top: 16px;
      margin-bottom: 4px;
    }
  }
}
