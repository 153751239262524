.dashboard__search-file-upload {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: auto;
}
.dashboard__order-rule {
  &:global(.ant-alert) {
    padding: 16px;
    margin-top: 64px;
    background: var(--primary-blue-1);
    border: none;
  }
}
.dashboard__order-info {
  margin-top: 12px;
  & :global(.ant-collapse) {
    display: flex;
    gap: 16px;
    &:first-child {
      &::before {
        order: 1;
        content: "";
        border-left: 1px solid var(--main-gray-35);
      }
    }
  }
  & :global(.ant-collapse-item) {
    flex: 1;
    order: 1;
    &:first-child {
      order: 0;
    }
  }
  & :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding: 0 0 8px;
  }
  & :global(.ant-collapse .ant-collapse-content > .ant-collapse-content-box) {
    padding: 0 16px;
  }
}
