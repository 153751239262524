.basket-checkout-order-success {
  width: 32.5%;
  min-width: 412px;
  margin: 0 auto;
  text-align: center;
}
.basket-checkout-order-success_icon {
  width: 56px;
  height: 56px;
  color: var(--success-default);
}
.basket-checkout-order-success_message {
  color: var(--dark);
}
