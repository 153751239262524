.consent-cookies-form__content {
  max-height: 468px;
  overflow-y: auto;
}
.consent-cookies-form__cookie {
  margin-bottom: 16px;
}
.consent-cookies-form__actions {
  margin-top: 24px;
}
.consent-cookies-form__button {
  margin-bottom: 12px;
}
