.tooltip {
  position: relative;
}
.tooltip-color-default :global(.ant-tooltip-arrow::before) {
  --antd-arrow-background-color: var(--dark);
}
.tooltip-color-default:global(.ant-tooltip .ant-tooltip-inner) {
  background: var(--dark);
}
.tooltip-color-blue :global(.ant-tooltip-arrow::before) {
  --antd-arrow-background-color: var(--primary-blue-8);
}
.tooltip-color-blue:global(.ant-tooltip .ant-tooltip-inner) {
  background: var(--primary-blue-8);
}
.tooltip-color-white :global(.ant-tooltip-arrow::before) {
  --antd-arrow-background-color: var(--white);
}
.tooltip-color-white:global(.ant-tooltip .ant-tooltip-inner) {
  color: var(--dark);
  background: var(--white);
}
.tooltip-gutter-no-gutter:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 0;
}
.tooltip-gutter-small:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 6px 8px;
}
.tooltip-gutter-middle:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 8px 10px;
}
.tooltip-gutter-large:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 12px 16px;
}
.tooltip-type-form-hint:global(.ant-tooltip) {
  width: 100%;
  max-width: 286px;
  height: auto;
  margin: 4px 0 0;
  font-size: 12px;
  border-radius: 4px;
}
.tooltip-type-secondary {
  width: 100%;
  height: auto;
  margin: 0 0 0 1px;
}
.tooltip-type-secondary:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 16px;
  margin: -16px 0 0;
  font-size: 12px;
  border-radius: 0;
}
.tooltip-type-table-hint:global(.ant-tooltip) {
  width: 308px;
  max-width: 92%;
}
.tooltip-type-table-hint :global(.ant-tooltip-arrow::before) {
  --antd-arrow-background-color: var(--main-gray-2);
}
.tooltip-type-table-hint:global(.ant-tooltip .ant-tooltip-inner) {
  padding: 12px;
  color: var(--gray-6);
  background: var(--main-gray-2);
}
