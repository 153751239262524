.accordion {
  width: 100%;
}
.accordion :global .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 16px 0;
}
.accordion :global .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 8px 0 0;
}
.accordion
  :global
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 0;
  font-size: 20px;
}
.accordion
  :global
  .ant-collapse-icon-position-left
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  left: 0;
  font-size: 20px;
}
.accordion :global .ant-collapse {
  background-color: transparent;
  border: 0;
}
.accordion :global .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header,
.accordion :global .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
  padding: 12px 30px 12px 0;
  border-bottom: 1px solid var(--main-gray-35);
}
.accordion :global .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header div,
.accordion
  :global
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  div {
  line-height: 1;
}
.accordion :global .ant-collapse .ant-collapse-content {
  border-top: 0;
}
.accordion :global .ant-collapse > .ant-collapse-item {
  border: 0;
}
.accordion .icon-size.icon-small svg {
  width: 12px;
  height: 12px;
}
.accordion .icon-size.icon-medium svg {
  width: 16px;
  height: 16px;
}

/* Sidebar Filter */
.accordion.sidebar-filter :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  padding: 8px 0;
}
.accordion.sidebar-filter
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text) {
  height: 24px;
  line-height: 24px;
}
.accordion.sidebar-filter
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon) {
  padding-inline-end: 4px;
}
.accordion.sidebar-filter :global(.ant-collapse-item:not(:first-child)) {
  border-top: 1px solid var(--blue-2);
}
.accordion.sidebar-filter :global(.ant-collapse-item > .ant-collapse-header) {
  border-bottom: 0;
}

/* Returns & Refunds */
.accordion.returns-and-refunds :global .ant-collapse-item:not(:last-child) {
  margin-bottom: 16px;
}
.accordion.returns-and-refunds :global .ant-collapse-item:not(:first-child) {
  padding-top: 8px;
  border-top: 1px solid var(--main-gray-35);
}
.accordion.returns-and-refunds :global .ant-collapse-item > .ant-collapse-header {
  padding: 10px 0;
  border: none;
}
.accordion.returns-and-refunds
  :global
  .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  max-width: 80%;
}
.accordion.returns-and-refunds :global .ant-collapse .ant-collapse-content-box {
  padding-top: 8px;
}
