.consent-cookies-view {
  margin-top: 16px;
  margin-left: 52px;
}
.consent-cookies-view__list:global(.ant-list .ant-list-items .ant-list-item) {
  flex-direction: column;
  gap: 8px;
  align-items: start;
  justify-content: flex-start;
  padding: 16px 0 0;
  margin-top: 16px;
  border-top: 1px solid var(--main-blue-gray-3);
}
