.consent-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 32px 0 20px;
  background: var(--white);
  box-shadow:
    0 2px 6px rgba(19 37 48 / 18%),
    0 0 18px rgba(19 37 48 / 13%);
  .consent-cookies__title {
    margin-bottom: 16px;
  }
}
