.name-address {
  p,
  h5 {
    margin: 0;
  }
  h5 {
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 8px;
  }
}
